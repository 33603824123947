import { IconsHandlerService } from '../../Services/Utils/IconsHandler.service';
import { headerData } from '../Components/main-table/main-table.component';
import { iBase64File } from '../Interfaces/Utils/iBase64File';
import { iButtonOptions } from '../Interfaces/Utils/iButtonOptions';

export class ExportOfferPopupClass {
  saveButtonOptions: iButtonOptions = {
    class: 'primary',
    text: 'Guardar',
    icon: {
      icon: true,
      image: IconsHandlerService.getTickIcon(),
    },
  };
  cancelButtonOptions: iButtonOptions = {
    class: 'tertiary',
    icon: {
      icon: true,
      image: IconsHandlerService.getCancelSkyBlueIcon(),
    },
    text: 'Cancelar',
  };

  stage: number = 0;

  exportOptions: { id: number; label: string }[] = [];

  action: string = '';

  selectedExportOption: number = 1;

  filesArray: iBase64File[] = [];

  dataSource: any[] = [];

  selectedRows: any[] = [];

  formedOffers: any[] = [];
  formedOffersComponents: any[] = [];
  formedOffersComponentsCharacteristics: any[] = [];

  // HeaderData
  headerData: headerData = [
    {
      variant: 'standard',
      display: 'Ofertas detectadas',
      key: 'offerNumber',
      alignment: 'start',
      width: '300px',
      ifNotContent: (row: any) => {
        return '-';
      },
    },
    {
      variant: 'standard',
      display: 'Nombre del cliente',
      key: 'clientName',
      alignment: 'center',
      width: '300px',
      ifNotContent: (row: any) => {
        return '-';
      },
    },
    {
      variant: 'standard',
      display: 'Componentes',
      key: 'components',
      width: '150px',
      ifNotContent: (row: any) => {
        return '-';
      },
    },
  ];
}
