import { IconsHandlerService } from '../../Services/Utils/IconsHandler.service';
import { iButtonOptions } from '../Interfaces/Utils/iButtonOptions';
import { iCreateOfferNgModels } from '../Interfaces/Utils/iCreateOfferNgModels';
import {
  iOfferHeaderData,
  IOfferHeaderData,
} from '../Interfaces/Utils/iOfferHeaderData';

export class EditOfferPopupClass {
  headerInfo: iOfferHeaderData = IOfferHeaderData.getEmptyObject();

  // ButtonOptions
  cancelButtonOptions: iButtonOptions = {
    class: 'tertiary',
    icon: {
      icon: true,
      image: IconsHandlerService.getCancelSkyBlueIcon(),
    },
    text: 'Cancelar',
  };
  deleteButtonOptions: iButtonOptions = {
    class: 'primary',
    icon: {
      icon: true,
      image: IconsHandlerService.getDeleteBluePaleIcon(),
    },
    text: 'Guardar',
  };

  // createOfferNgModels
  createOfferNgModels: iCreateOfferNgModels = {
    offerNumber: null,
    client: null,
    description: null,
    observations: null,
    po: null,
  };
}
