<div class="popupGeneralContainer">
  <div class="header">
    <p>Editar oferta</p>
    <mat-icon (click)="closePopup(false)">close</mat-icon>
  </div>
  <div class="body">
    <create-edit-offer
      [createOfferNgModels]="extraClass.createOfferNgModels"
      (onChange)="onOfferChange($event)"
    ></create-edit-offer>
  </div>

  <div class="footer">
    <custom-button
      [options]="extraClass.cancelButtonOptions"
      (click)="closePopup(false)"
    />
    <custom-button
      [options]="extraClass.deleteButtonOptions"
      (click)="closePopup(extraClass.createOfferNgModels)"
    />
  </div>
</div>
