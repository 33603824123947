import { iOptionsCustomInput } from '@quasar-dynamics/ngx-qd-design-system';
import { IconsHandlerService } from '../../Services/Utils/IconsHandler.service';
import { iButtonOptions } from '../Interfaces/Utils/iButtonOptions';
import {
  ISignatureInfo,
  iSignatureInfo,
} from '../Interfaces/Utils/iSignatureInfo';

export class SignatureManagementPopupClass {
  signatureInfo: iSignatureInfo = ISignatureInfo.getEmptyObject();

  saveButtonOptions: iButtonOptions = {
    class: 'primary',
    text: 'Guardar',
    icon: {
      icon: true,
      image: IconsHandlerService.getTickIcon(),
    },
  };

  //   Custom input options
  documentVersionCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Versión del documento',
    type: 'text',
    classList: 'dark-input',
    label: 'Versión del documento',
  };
  readyCustomInputOptions: iOptionsCustomInput = {
    placeholder: '---',
    type: 'text',
    classList: 'dark-input',
    label: 'Preparado',
  };
  readyDateCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Introduzca la fecha',
    type: 'date',
    classList: 'dark-input',
    label: '',
  };
  checkedCustomInputOptions: iOptionsCustomInput = {
    placeholder: '---',
    type: 'text',
    classList: 'dark-input',
    label: 'Revisado',
  };
  checkedDateCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Introduzca la fecha',
    type: 'date',
    classList: 'dark-input',
    label: '',
  };
  aprovedCustomInputOptions: iOptionsCustomInput = {
    placeholder: '---',
    type: 'text',
    classList: 'dark-input',
    label: 'Aprobado',
  };
  aprovedDateCustomInputOptions: iOptionsCustomInput = {
    placeholder: 'Introduzca la fecha',
    type: 'date',
    classList: 'dark-input',
    label: '',
  };
}
