import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditOfferPopupClass } from '../../Shared/Class/EditOfferPopupClass';
import { OfferService } from '../../Services/Api/Offer.service';
import { DefaultDependenciesInjectorService } from '../../Services/Utils/default-dependencies-injector.service';
import {
  iResultHttp,
  StaticUtilitiesService,
} from '@quasar-dynamics/ngx-qd-design-system';
import { filter, takeUntil } from 'rxjs';
import { iOfferHeaderData } from '../../Shared/Interfaces/Utils/iOfferHeaderData';
import { iCreateOfferNgModels } from '../../Shared/Interfaces/Utils/iCreateOfferNgModels';

@Component({
  selector: 'Edit-Offer-Popup',
  templateUrl: './Edit-Offer-Popup.component.html',
  styleUrls: ['./Edit-Offer-Popup.component.scss'],
})
export class EditOfferPopupComponent implements OnInit {
  extraClass: EditOfferPopupClass = new EditOfferPopupClass();

  constructor(
    public dialogRef: MatDialogRef<EditOfferPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private offerSE: OfferService,
    private DDIS: DefaultDependenciesInjectorService
  ) {}

  ngOnInit() {
    this.getOfferInfo();
  }

  /**
   * HANDLERS
   */

  successGetOfferInfoHandler(res: iResultHttp) {
    const { data } = res;
    const { project } = data;
    this.extraClass.headerInfo = project;
    this.projectAdapter(project);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }

  projectAdapter(project: iOfferHeaderData) {
    this.extraClass.createOfferNgModels = {
      offerNumber: project.offerNumber,
      client: project.clientId,
      description: project.description,
      observations: project.observations,
      po: project.po,
    };
  }

  onOfferChange(createOfferNgModels: iCreateOfferNgModels) {
    this.extraClass.createOfferNgModels = createOfferNgModels;
  }
  /**
   * API CALLS
   */

  getOfferInfo() {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.offerSE.getSingle(behaviorSubject, this.modalData.id);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetOfferInfoHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
