<div class="popupGeneralContainer">
  <div class="header">
    <p>{{ extraClass.action }} .xlxs</p>
    <mat-icon (click)="closePopup(false)">close</mat-icon>
  </div>
  <div class="body">
    @if(extraClass.stage === 0){

    <mat-radio-group
      class="export-radio-buttons"
      [(ngModel)]="extraClass.selectedExportOption"
    >
      @for (option of extraClass.exportOptions; track option) {
      <mat-radio-button class="example-radio-button" [value]="option.id">{{
        option.label
      }}</mat-radio-button>
      }
    </mat-radio-group>

    @if(extraClass.action === 'Importar'){
    <add-file
      style="width: 100%"
      [acceptedFileTypesArray]="[
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ]"
      [maxFileSizeMB]="10"
      [filesArray]="extraClass.filesArray"
      (emitFiles)="getFile($event)"
    />

    } } @else if (extraClass.stage === 1) {
    <main-table
      [headerData]="extraClass.headerData"
      [dataSource]="extraClass.dataSource"
      [selectMultiple]="true"
      (changeSelectedRows)="changeSelectedRows($event)"
    ></main-table>
    }
  </div>

  <div class="footer">
    <custom-button
      (click)="closePopup(false)"
      [options]="extraClass.cancelButtonOptions"
    />
    @if(extraClass.stage === 0){
    <custom-button
      (click)="
        extraClass.action === 'Importar' ? importOptionHandler() : onSaveClick()
      "
      [options]="extraClass.saveButtonOptions"
      [class.disabled]="
        extraClass.action === 'Importar' && extraClass.filesArray.length === 0
      "
    />
    } @else{

    <custom-button
      (click)="ImportAndClose()"
      [options]="extraClass.saveButtonOptions"
      [class.disabled]="extraClass.filesArray.length === 0"
    />
    }
  </div>
</div>
