<div class="popupGeneralContainer">
  <div class="header">
    <p>Gestión de firmas</p>
    <mat-icon (click)="closePopup(false)">close</mat-icon>
  </div>
  <div class="body">
    <div class="formLine">
      <customInput
        style="width: 100%"
        [options]="extraClass.documentVersionCustomInputOptions"
        [(ngModel)]="extraClass.signatureInfo.version"
      ></customInput>
    </div>
    <div class="formLine shortAndLong">
      <customInput
        [options]="extraClass.readyCustomInputOptions"
        [(ngModel)]="extraClass.signatureInfo.whoPreparesInitialism"
      ></customInput>
      <customInput
        [options]="extraClass.readyDateCustomInputOptions"
        [selectedDate]="extraClass.signatureInfo.preparationDate"
        (change)="dateChangeHandler($event, 'preparationDate')"
      ></customInput>
    </div>
    <div class="formLine shortAndLong">
      <customInput
        [options]="extraClass.checkedCustomInputOptions"
        [(ngModel)]="extraClass.signatureInfo.whoReviewsInitialism"
      ></customInput>
      <customInput
        [options]="extraClass.checkedDateCustomInputOptions"
        [selectedDate]="extraClass.signatureInfo.reviewDate"
        (change)="dateChangeHandler($event, 'reviewDate')"
      ></customInput>
    </div>
    <div class="formLine shortAndLong">
      <customInput
        [options]="extraClass.aprovedCustomInputOptions"
        [(ngModel)]="extraClass.signatureInfo.whoApprovesInitialism"
      ></customInput>
      <customInput
        [options]="extraClass.aprovedDateCustomInputOptions"
        [selectedDate]="extraClass.signatureInfo.approvalDate"
        (change)="dateChangeHandler($event, 'approvalDate')"
      ></customInput>
    </div>
  </div>

  <div class="footer">
    <br />
    <custom-button
      (click)="closePopup(extraClass.signatureInfo)"
      [options]="extraClass.saveButtonOptions"
      [class.disabled]="!extraClass.signatureInfo.version"
    />
  </div>
</div>
