export interface iSignatureInfo {
  whoPreparesInitialism: string | null;
  preparationDate: Date | null;
  whoReviewsInitialism: string | null;
  reviewDate: Date | null;
  whoApprovesInitialism: string | null;
  approvalDate: Date | null;
  version: number | null;
  [key: string]: any;
}

export class ISignatureInfo {
  static getEmptyObject(): iSignatureInfo {
    return {
      whoPreparesInitialism: null,
      preparationDate: null,
      whoReviewsInitialism: null,
      reviewDate: null,
      whoApprovesInitialism: null,
      approvalDate: null,
      version: null,
    };
  }
}
