export interface iOfferHeaderData {
  id: number;
  offerNumber: string;
  po: string | null;
  clientId: number | null;
  description: string | null;
  observations: string | null;
  clientAcronym: string | null;
  version: number;
  documentId: number | null;
}

export class IOfferHeaderData {
  static getEmptyObject(): iOfferHeaderData {
    return {
      id: 0,
      offerNumber: '',
      po: '',
      clientId: 0,
      description: '',
      observations: '',
      clientAcronym: '',
      version: 0,
      documentId: 0,
    };
  }
}
