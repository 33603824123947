import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExportOfferPopupClass } from '../../Shared/Class/ExportOfferPopupClass';
import { iExcelOfferToImport } from '../../Shared/Interfaces/Utils/iExcelOfferToImport';
import { ExcelService } from '../../Services/Utils/Excel.service';
import { iExcelOfferImportToSend } from '../../Shared/Interfaces/Utils/iExcelOfferImportToSend';
import { DefaultDependenciesInjectorService } from '../../Services/Utils/default-dependencies-injector.service';
import {
  iResultHttp,
  StaticUtilitiesService,
} from '@quasar-dynamics/ngx-qd-design-system';
import { iExcelOfferComponents } from '../../Shared/Interfaces/Utils/iExcelOfferComponents';
import { iExcelOfferComponentsCharacteristics } from '../../Shared/Interfaces/Utils/iExcelOfferComponentsCharacteristics';
import { filter, takeUntil } from 'rxjs';
import { OfferService } from '../../Services/Api/Offer.service';
import { SelectedDataTableBridgeService } from '../../Services/Utils/SelectedDataTableBridge.service';

@Component({
  selector: 'Import-Export-Offer-Popup',
  templateUrl: './Import-Export-Offer-Popup.component.html',
  styleUrls: ['./Import-Export-Offer-Popup.component.scss'],
})
export class ImportExportOfferPopupComponent implements OnInit {
  extraClass: ExportOfferPopupClass = new ExportOfferPopupClass();

  constructor(
    public dialogRef: MatDialogRef<ImportExportOfferPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private excelSE: ExcelService,
    private DDIS: DefaultDependenciesInjectorService,
    private offerSE: OfferService,
    private selectedDataTableBridgeSE: SelectedDataTableBridgeService
  ) {}

  ngOnInit() {
    this.setOptionsData();
    // this.selectedDataTableBridgeSE.clearSelectedData();
  }

  /**
   * HANDLERS
   */

  successCreateOfferBulkHandler(res: iResultHttp) {
    this.closePopup(true);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }

  successCreateOfferComponentBulkHandler(res: iResultHttp) {
    this.closePopup(true);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }

  successGetInfoForOfferExportBulkHandler(res: iResultHttp) {
    const { data } = res;
    this.excelSE.exportExcelOffer(data);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }

  successCreateOfferComponentCharacteristicBulkHandler(res: iResultHttp) {
    this.closePopup(true);
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  setOptionsData() {
    this.extraClass.exportOptions = this.modalData.data.exportOptions;
    this.extraClass.action = this.modalData.data.action;
    this.extraClass.saveButtonOptions.text = this.modalData.data.action;
    this.extraClass.saveButtonOptions.icon.image = this.modalData.data.icon;
  }

  getFile(event: any) {
    this.extraClass.filesArray = event;
  }

  onSaveClick() {
    if (this.extraClass.action === 'Exportar') {
      this.closePopup(this.extraClass.selectedExportOption);
    }
  }

  async getFileOffer(event: any) {
    const file = event[0].file;
    try {
      const data: iExcelOfferToImport[] = await this.excelSE.importExcelOffer(
        file
      );
      const objectToPass: iExcelOfferImportToSend[] =
        this.excelSE.mountObjectOfferImport(data);
      this.extraClass.formedOffers = objectToPass;
      this.formOfferLines(objectToPass);
      this.extraClass.stage = 1;
    } catch (error: any) {
      this.extraClass.stage = 0;

      StaticUtilitiesService.showWarning(
        error,
        'Asegúrate de que los campos requeridos han sido rellenados'
      );
    }
  }

  async getFileOfferComponents(event: any) {
    const file = event[0].file;
    try {
      const data: iExcelOfferComponents =
        await this.excelSE.importExcelOfferComponents(file);

      const mountedOfferComponents =
        this.excelSE.mountObjectOfferComponentImport(data);
      this.extraClass.formedOffersComponents = mountedOfferComponents;
      this.formOfferLines(mountedOfferComponents);
      this.extraClass.stage = 1;
    } catch (error: any) {
      this.extraClass.stage = 0;

      StaticUtilitiesService.showWarning(
        error,
        'Asegúrate de que los campos requeridos han sido rellenados'
      );
    }
  }

  async getFileOfferComponentsCharacteristics(event: any) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const file = event[0].file;

    try {
      const data: iExcelOfferComponentsCharacteristics =
        await this.excelSE.importExcelOfferComponentsCharacteristics(file);

      const mountedOfferComponentsCharacteristics =
        this.excelSE.mountObjectOfferComponentCharacteristicsImport(data);
      this.extraClass.formedOffersComponentsCharacteristics =
        mountedOfferComponentsCharacteristics;
      this.formOfferLines(mountedOfferComponentsCharacteristics);
      this.extraClass.stage = 1;
      this.DDIS.generalLoaderSE.removeFromLoaderAmount();
    } catch (error: any) {
      this.extraClass.stage = 0;

      StaticUtilitiesService.showWarning(
        error,
        'Asegúrate de que los campos requeridos han sido rellenados'
      );
      this.DDIS.generalLoaderSE.removeFromLoaderAmount();
    }
  }

  getFilteredOffers(offers: any[], selectedRows: any[]): any[] {
    if (selectedRows.length === 0) {
      return offers;
    } else {
      const indexs = selectedRows.map((item) => item.id);
      return offers.filter((item, index) => indexs.includes(index));
    }
  }

  ImportAndClose() {
    this.extraClass.stage = 1;
    let objectToSend: any[] = [];

    switch (this.extraClass.selectedExportOption) {
      case 1:
        objectToSend = this.getFilteredOffers(
          this.extraClass.formedOffers,
          this.extraClass.selectedRows
        );
        this.createOfferBulk(objectToSend);
        break;
      case 2:
        objectToSend = this.getFilteredOffers(
          this.extraClass.formedOffersComponents,
          this.extraClass.selectedRows
        );
        this.createOfferComponentBulk(objectToSend);
        break;
      case 3:
        objectToSend = this.getFilteredOffers(
          this.extraClass.formedOffersComponentsCharacteristics,
          this.extraClass.selectedRows
        );
        this.createOfferComponentCharacteristicBulk(objectToSend);
        break;
      default:
        break;
    }

    this.selectedDataTableBridgeSE.clearSelectedData();
  }
  changeSelectedRows(rows: any) {
    this.extraClass.selectedRows = rows;
  }

  importOptionHandler() {
    switch (this.extraClass.selectedExportOption) {
      case 1:
        this.getFileOffer(this.extraClass.filesArray);
        break;
      case 2:
        this.getFileOfferComponents(this.extraClass.filesArray);
        break;
      case 3:
        this.getFileOfferComponentsCharacteristics(this.extraClass.filesArray);
        break;
      default:
        break;
    }
  }

  formOfferLines(objectToPass: any[]) {
    this.extraClass.dataSource = objectToPass.map((item, index) => {
      return {
        offerNumber: item.offer.offerNumber,
        components: item.component?.length ?? '-',
        clientName: item.client.clientName,
        id: index,
      };
    });
  }

  /**
   * API CALLS
   */

  createOfferBulk(objectToPass: iExcelOfferImportToSend[]) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.offerSE.createBulk(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateOfferBulkHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  createOfferComponentBulk(objectToPass: any) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.offerSE.createOfferComponentBulk(behaviorSubject, objectToPass);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateOfferComponentBulkHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  getInfoForOfferExportBulk(ids: number[]) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.offerSE.getInfoForOfferExportBulk(behaviorSubject, { idOffers: ids });
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetInfoForOfferExportBulkHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }

  createOfferComponentCharacteristicBulk(objectToPass: any) {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.offerSE.createOfferComponentCharacteristicBulk(
      behaviorSubject,
      objectToPass
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () =>
              this.successCreateOfferComponentCharacteristicBulkHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
