import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
} from '@quasar-dynamics/ngx-qd-design-system';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OfferService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  getAll(behaviorSubject: BehaviorSubject<any>, params: any) {
    this.http
      .get(this.urlApi + '/offer' + this.optionsGet(params), this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAll,
            args: [behaviorSubject, params],
          });
        },
      });
  }

  getInfoForOfferExportBulk(
    behaviorSubject: BehaviorSubject<any>,
    params: any
  ) {
    this.http
      .get(
        this.urlApi + '/offer/bulk' + this.optionsGet(params),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getInfoForOfferExportBulk,
            args: [behaviorSubject, params],
          });
        },
      });
  }

  getInfoForOfferComponentExportBulk(
    behaviorSubject: BehaviorSubject<any>,
    params: any
  ) {
    this.http
      .get(
        this.urlApi + '/offer/component/bulk' + this.optionsGet(params),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getInfoForOfferComponentExportBulk,
            args: [behaviorSubject, params],
          });
        },
      });
  }

  getInfoForOfferComponentCharacteristicsExportBulk(
    behaviorSubject: BehaviorSubject<any>,
    params: any
  ) {
    this.http
      .get(
        this.urlApi + '/offer/component/field/bulk' + this.optionsGet(params),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getInfoForOfferComponentCharacteristicsExportBulk,
            args: [behaviorSubject, params],
          });
        },
      });
  }

  getSingle(
    behaviorSubject: BehaviorSubject<any>,
    offerId: number,
    params?: any
  ) {
    this.http
      .get(
        this.urlApi + '/offer/' + offerId + this.optionsGet(params),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getSingle,
            args: [behaviorSubject, offerId, params],
          });
        },
      });
  }

  update(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .put(`${this.urlApi}/offer/${data.id}`, data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data.data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.update,
            args: [behaviorSubject, data],
          });
        },
      });
  }

  create(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http.post(this.urlApi + '/offer', data, this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: data.data,
        });
        this.sendNextObservable(behaviorSubject, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message,
        });
        this.sendNextObservable(behaviorSubject, this.res, true, {
          method: this.create,
          args: [behaviorSubject, data],
        });
      },
    });
  }

  createBulk(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .post(this.urlApi + '/offer/bulk', data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data.data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.createBulk,
            args: [behaviorSubject, data],
          });
        },
      });
  }

  createOfferComponentBulk(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .post(this.urlApi + '/offer/component/bulk', data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data.data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.createOfferComponentBulk,
            args: [behaviorSubject, data],
          });
        },
      });
  }

  createOfferComponentCharacteristicBulk(
    behaviorSubject: BehaviorSubject<any>,
    data: any
  ) {
    this.http
      .post(this.urlApi + '/offer/component/field/bulk', data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data.data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.createOfferComponentCharacteristicBulk,
            args: [behaviorSubject, data],
          });
        },
      });
  }

  delete(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http.delete(`${this.urlApi}/offer/${id}`, this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: data.data,
        });
        this.sendNextObservable(behaviorSubject, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message,
        });
        this.sendNextObservable(behaviorSubject, this.res, true, {
          method: this.delete,
          args: [behaviorSubject, id],
        });
      },
    });
  }
}
