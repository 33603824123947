import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SignatureManagementPopupClass } from '../../Shared/Class/SignatureManagementPopupClass';
import { SignatureService } from '../../Services/Api/Signature.service';
import {
  iResultHttp,
  StaticUtilitiesService,
} from '@quasar-dynamics/ngx-qd-design-system';
import { filter, takeUntil } from 'rxjs';
import { DefaultDependenciesInjectorService } from '../../Services/Utils/default-dependencies-injector.service';

@Component({
  selector: 'Signature-Management-Popup',
  templateUrl: './Signature-Management-Popup.component.html',
  styleUrls: ['./Signature-Management-Popup.component.scss'],
})
export class SignatureManagementPopupComponent implements OnInit {
  extraClass: SignatureManagementPopupClass =
    new SignatureManagementPopupClass();

  constructor(
    public dialogRef: MatDialogRef<SignatureManagementPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private signatureSE: SignatureService,
    private DDIS: DefaultDependenciesInjectorService
  ) {}

  ngOnInit() {
    this.getSignatureInfo();
  }

  /**
   * HANDLERS
   */

  successGetSignatureInfoHandler(res: iResultHttp) {
    const { data } = res;
    this.extraClass.signatureInfo = data;
    this.DDIS.generalLoaderSE.removeFromLoaderAmount();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }

  dateChangeHandler(event: any, key: string) {
    this.extraClass.signatureInfo[key] = event;
  }

  /**
   * API CALLS
   */

  getSignatureInfo() {
    this.DDIS.generalLoaderSE.addToLoaderAmount();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.signatureSE.getSingle(behaviorSubject, this.modalData.documentId);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetSignatureInfoHandler(res),
          },
          {
            method: () => this.DDIS.generalLoaderSE.removeFromLoaderAmount(),
            error: true,
          },
        ]);
      });
  }
}
